export const fr = {
  NATURAL_PERSON: 'Personne physique',
  LEGAL_PERSON: 'Personne morale',
  COMMUNICATION: 'Communication',
  JURIDIQUE: 'Juridique',
  BACK_OFFICE: 'Back Office',
  CONSEIL: 'Conseil',
  CABINET: 'Cabinet',
  COMPTABILITE: 'Comptabilité',
  ROLE_CLIENT: 'Client',
  ROLE_COLLABORATEUR: 'Collaborateur',
  ROLE_ADMIN: 'Administrateur',
  ROLE_RESPONSABLE_POLE: 'Responsable de pôle',
  ROLE_DIRIGEANT: 'Dirigeant',
  YES: 'Oui',
  NO: 'Non',
  DONT_KNOW: 'Je ne sais pas',
  USER_INACTIVE: {
    detail: 'Votre compte utilisateur est désactivé',
    summary: 'Erreur de connexion',
  },
  USER_EMAIL_EXIST: {
    detail: 'Un compte utilisateur existe déja avec cet email',
    summary: 'Erreur de création de compte',
  },
  WRONG_RESET_PASSWORD_TOKEN: {
    detail: 'Le lien de création/modification de mot de passe est erroné',
    summary: 'Erreur',
  },
  RESET_PASSWORD_TOKEN_EXPIRED: {
    detail: 'Le lien de création/modification de mot de passe a expiré',
    summary: 'Erreur',
  },
  // TYPES DE RELATIONS ENTRE PERSONNES PHYSIQUES
  MARIE: 'Marié·e',
  PACSE: 'Pacsé.e',
  CONCUBIN: 'Concubin',
  PARENT: 'Parent',
  ENFANT: 'Enfant',
  FRATRIE: 'Fratrie',
  GRAND_PARENT: 'Grand parent',
  PETIT_ENFANT: 'Petit enfant',
  ONCLE_TANTE: 'Oncle / tante',
  NEVEU_NIECE: 'Neveu / nièce',
  TIERS: 'Tiers',
  AUTRE_FAMILLE: 'Autre famille',
  // STATUT CLIENT - PERSONNES PHYSIQUES ET MORALES
  CLIENT: 'Client',
  FOURNISSEUR: 'Fournisseur',
  CONTACT: 'Contact',
  PROSPECT: 'Prospect',
  // CIVILITÉ - PERSONNE PHYSIQUE
  MME: 'Mme',
  MX: 'Mx',
  M: 'M.',
  // IMPORTANCE - TÂCHE
  VERY_IMPORTANT: 'Très important',
  IMPORTANT: 'Important',
  CURRENT: 'Courant',
  // STATUT - TÂCHE
  PLANNED: 'Planned',
  TODO: 'Todo',
  DOING: 'Doing',
  DONE: 'Done',
  // STRUCTURE JURIDIQUE - PERSONNE MORALE
  EI: 'EI',
  EIRL: 'EIRL',
  EURL: 'EURL',
  SARL: 'SARL',
  SAS: 'SAS',
  SASU: 'SASU',
  SA: 'SA',
  ASSOCIATION: 'Association',
  AUTO_ENTREPRISE: 'Auto-entreprise',
  SC_SCI: 'SC/SCI',
  // ORIGINE DU CLIENT - PERSONNE PHYSIQUE
  EMD: 'EMD',
  CPS: 'CPS',
  ACTI: 'Acti',
  GOSSELIN: 'Gosselin',
  WING_FINANCE: 'Wing Finance',
  ARTHEMIS: 'Arthemis',
  PERFORMANCES_BY_EMD: 'Performances by EMD',
  ANTOINE_LEDUC: 'Antoine Leduc',
  CARVING: 'Carving',

  // IMMOBILIER
  IMMO_TYPE_PRINCIPALE: 'Résidence principale',
  IMMO_TYPE_SECONDAIRE: 'Résidence secondaire',
  IMMO_TYPE_FONCIER_FORESTIER: 'Foncier forestier',
  IMMO_TYPE_LOCATIF: 'Immobilier locatif',
  IMMO_TYPE_SCPI: 'SCPI',

  IMMO_DETENTEUR_AUTRE: 'Autre',
  IMMO_DETENTEUR_MOI: 'Moi',
  IMMO_DETENTEUR_CONJOINT: 'Mon conjoint',
  IMMO_DETENTEUR_NOUS: 'Nous',
  IMMO_DETENTION_PLEINE_PROPRIETE: 'Pleine propriété',
  IMMO_DETENTION_INDIVISION: 'Indivision',
  IMMO_DETENTION_DEMEMBREMENT: 'Démembrement',

  IMMO_NATURE_MAISON: 'Maison',
  IMMO_NATURE_IMMEUBLE: 'Immeuble',
  IMMO_NATURE_APPARTEMENT: 'Appartement',
  IMMO_NATURE_GARAGE_PARKING: 'Garage / Parking',
  IMMO_NATURE_TERRAIN: 'Terrain',
  IMMO_NATURE_AUTRE: 'Autre',

  // FINANCIER
  CREDIT_TYPE_AMORTISSABLE : 'Amortissable',
  CREDIT_TYPE_IN_FINE : 'In fine',

  CREDIT_SOUSCRIPTEUR_MOI : 'Moi',
  CREDIT_SOUSCRIPTEUR_CONJOINT : 'Mon conjoint',
  CREDIT_SOUSCRIPTEUR_NOUS : 'Nous',

  AVOIR_FI_SOUSCRIPTEUR_MOI : 'Moi',
  AVOIR_FI_SOUSCRIPTEUR_CONJOINT : 'Mon conjoint',
  AVOIR_FI_SOUSCRIPTEUR_NOUS : 'Nous',
  AVOIR_FI_SOUSCRIPTEUR_ENFANT : 'Enfant(s)',

  AVOIR_FI_COMPTE_CHEQUE : 'Compte chèque / compte courant',
  AVOIR_FI_COMPTE_LIVRET : 'Compte sur livret',
  AVOIR_FI_LIVRET_A : 'Livret A',
  AVOIR_FI_LIVRET_DEV_DURABLE_SOLIDAIRE : 'Livret développement durable et solidaire',
  AVOIR_FI_LIVRET_JEUNE : 'Livret jeune',
  AVOIR_FI_LIVRET_EPARGNE_POPULAIRE : 'Livret d\'Epargne populaire',
  AVOIR_FI_COMPTE_EPARGNE_LOGEMENT : 'Compte Epargne logement',
  AVOIR_FI_COMPTE_A_TERME : 'Compte à terme ',
  AVOIR_FI_AUTRE_DISPONIBILITE : 'Autre disponibilité',
  AVOIR_FI_PEL : 'PEL',
  AVOIR_FI_COMPTE_TITRE : 'Compte titre',
  AVOIR_FI_PEA : 'PEA',
  AVOIR_FI_CROWDFUNDING : 'Crowdfunding ',
  AVOIR_FI_PEA_PME : 'PEA PME',
  AVOIR_FI_FIP : 'FIP',
  AVOIR_FI_FCPR : 'FCPR',
  AVOIR_FI_FCPI : 'FCPI',
  AVOIR_FI_PART_HOLDING : 'Parts de holding ',
  AVOIR_FI_TITRE_EN_DIRECT_COTE_OU_NON : 'Titres en direct côtés ou non côtés',
  AVOIR_FI_ASSURANCE_VIE : 'Assurance vie',
  AVOIR_FI_PEP : 'PEP',
  AVOIR_FI_CONTRAT_VIE_GENERATION : 'Contrat vie génération',
  AVOIR_FI_CONTRAT_CAPITALISATION : 'Contrat de capitalisation',
  AVOIR_FI_PER : 'PER',
  AVOIR_FI_PERCO : 'PERCO',
  AVOIR_FI_CONTRAT_RETRAITE_MADELIN : 'Contrat retraite Madelin',
  AVOIR_FI_PEE : 'PEE',
  AVOIR_FI_PEI : 'PEI',
  AVOIR_FI_PREFON : 'Prefon',
  AVOIR_FI_PERP : 'PERP',
  AVOIR_FI_ART_83 : 'Art 83',
  AVOIR_FI_AUTRE : 'Autre type d\'avoir financier',

  DISPONIBILITE: 'Disponibilité',
  VALEUR_IMMO : 'Valeur mobilière',
  ASSURANCE_VIE : 'Assurance vie / Contrat de capitalisation',
  EPARGNE_RETRAITE : 'Epargne retraite et salariale',
  AUTRE : 'Autre',

  PRET_CONSO : 'Prêt Conso',
  PRET_ETUDIANT : 'Prêt Etudiant',
  PRET_LOA : 'Prêt LOA',
  PRET_LDD : 'Prêt LDD',

  ZERO_CINQ_MILLE : 'de 0 à 5 000€',
  CINQ_MILLE_QUINZE_MILLE : 'de 5 000 à 15 000€',
  QUINZE_MILLE_VINGT_CINQ_MILLE : 'de 15 000 à 25 000€',
  PLUS_VINGT_CINQ_MILLE : '25 000 € et plus',

  //Mesure de protection
  TUTELLE : 'Tutelle',
  CURATELLE : 'Curatelle',
  SAUVEGARDE_JUSTICE : 'Sauvegarde de justice',

  //Situation matrimoniale
  CELIBATAIRE : 'Célibataire',
  CONCUBINAGE : 'Concubin·e / Union libre',
  SEPARE : 'Séparé·e / en instance de divorce',
  DIVORCE : 'Divorcé.e',
  VEUF : 'Veuf.ve',

  //Regime matrimonial
  SEPARATION_DE_BIENS_PURE_ET_SIMPLE : 'Séparation de biens pure et simple',
  SEPARATION_DE_BIENS_AVEC_ADJONCTION_DE_SOCIETE_ACQUETS : 'Séparation de biens avec adjonction de société d’acquêts',
  COMMUNAUTE_UNIVERSELLE : 'Communauté universelle',
  COMMUNAUTE_REDUITE_AUX_ACQUETS : 'Communauté Réduite aux acquêts – régime légal depuis 02/1966',
  REGIME_FRANCO_ALLEMAND : 'Régime Franco-allemand',
  PARTICIPATION_AUX_ACQUETS : 'Participation aux acquêts',

  //Regime pacsimonial
  SEPARATION_DE_BIENS : 'Séparation de biens',
  INDIVISION : 'Indivisions',

  //Situation pro
  SALARIE : 'Salarié',
  NON_SALARIE : 'Non salarié',
  RETRAITE : 'Retraité',
  SANS_EMPLOI : 'Sans emploi',
  CHOMEUR : 'Chômeur',
  ETUDIANT : 'Etudiant / écolier / en scolarité',

  //CIVILE
  CIVIL_PI : 'Pièce d\'identité',
  CIVIL_JUSTIF_DOMICILE : 'Justificatif de domicile',
  CIVIL_DONATION_DERNIER_VIVANT : 'Donation au dernier vivant',
  CIVIL_LIVRET_FAMILLE : 'Livret de famille',

  //MATRIMONIALE
  MATRIMONIAL_JUGEMENT_DIVORCE : 'Jugement de divorce',
  MATRIMONIAL_CONTRAT_MARIAGE : 'Contrat de mariage/pacs',

  //IMMO
  IMMO_AMORTISSEMENT : 'Tableau d’amortissement',
  IMMO_OFFRE_PRET : 'Offre de prêt',
  IMMO_ATTEST_NOTARIAL : 'Attestation notariale',
  IMMO_TAXE_FONCIERE : 'Taxe foncière',
  IMMO_TITRE_PROPRIETE : 'Titre de propriété',
  IMMO_STATUT_SCI : 'Derniers statuts de la SCI',

  //DIVERS
  DIVERS_TITRE_PROPRIETE : 'Certificat ou titre de propriété',
  DIVERS_JUSTIF_INVEST : 'Autre justificatif d’investissement',

  //FINANCIER
  FINANCIER_RELEVE_COMPTE : 'Relevé de compte',
  FINANCIER_RELEVE_SITUATION : 'Relevé de situation',
  FINANCIER_TITRE_PROPRIETE : 'Titre de propriété',

  //IMPOT
  IMPOT_AVIS : 'Avis d\'imposition',
  IMPOT_DECLARATION : 'Déclaration d’impôts',

  //CREDIT
  CREDIT_OFFRE : 'Offre de prêt crédit consommation',
  CREDIT_AMORTISSEMENT : 'Tableau d’amortissement crédit consommation',

  TESTAMENT : 'Testament',
  ACTE_DONATION : 'Actes de donations',
  RECUEIL : 'Recueil',

  //PREVOYANCE
  PREVOYANCE_CERTIF : 'Certificat d\'adhesion de contrat prévoyance',
  PREVOYANCE_CONDITION : 'Conditions particulières de contrat prévoyance',

  //AUTRE
  AUTRE_CIVIL : 'Autre document',
  AUTRE_CREDIT : 'Autre document',
  AUTRE_IMMO : 'Autre document',
  AUTRE_FINANCIER : 'Autre document',
  AUTRE_IMPOT : 'Autre document',
  AUTRE_TESTAMENT : 'Autre document',
  AUTRE_PREVOYANCE : 'Autre document',
  AUTRE_DONATION : 'Autre document',
  AUTRE_DIVERS : 'Autre document',
  AUTRE_DOC : 'Autre document',

  //CONTACT TYPES
  CONTACT_PERSONEL : 'Personnel',
  CONTACT_PROFESSIONNEL : 'Professionnel',
  ADDRESS_PRINCIPALE : 'Résidence principale',
  ADDRESS_SECONDAIRE : 'Résidence secondaire',
  ADDRESS_PROFESSIONNELLE : 'Adresse professionnelle',

  //SEGMENTATION CLIENT
  CHAMOIS: '1 - Chamois',
  FUSEE: '1bis - Fusée',
  ETOILEDOR: '2 - Etoile d\'Or',
  FLOCON: '3 - Flocon',
  SIFFLOTE: '4 - Sifflote',
  GAROLOU: '5 - Garolou',

  CHARGE_TOTALE: 'Totale',
  CHARGE_PARTAGEE: 'Partagée',
  CHARGE_NO: 'Non',

  ADOPTION_SIMPLE: 'Simple',
  ADOPTION_PLEINIERE: 'Plénière',

  ENFANT_DE_MOI: 'Moi',
  ENFANT_DE_NOUS: 'Mon conjoint et moi',
  ENFANT_DE_CONJOINT: 'Mon conjoint',


  //LABEL CONSEILLE
  WELCOME_TITLE: 'Bienvenue',
  WELCOME_TEXT1: 'Mot de passe',
  WELCOME_TEXT2: 'Création automatique du compte',
  PREZ_TEXT_TIERS: 'Informations tiers',
  REQUEST_FIRST_TITLE: 'Tout d\'abord, faisons un peu connaissance…',
  REQUEST_FIRST_COMMENT: '"Vous rêvez de monter une entreprise ? Non pas du tout vous avez envie d\'explorer les mers !\n' +
    'Non non non, plutôt de préparer votre retraite ! Ah, mais vous avez peut-être des enfants et vous pensez à leurs études…"',
  REQUEST_UPDATE_TITLE: 'Quoi de neuf depuis le ',
  REQUEST_UPDATE_COMMENT: 'Commentaire',
  STEP_VOUS_STEPPER_TITLE: 'Vos informations',
  STEP_VOUS_ETAT_CIVIL: 'Etat civil',
  STEP_VOUS_SITUATION_CIVILE: 'Situation civile',
  STEP_VOUS_CONTACT: 'Coordonnées',
  STEP_VOUS_JOB: 'Situation professionnelle',
  STEP_VOUS_FAMOUS: 'Célébrité',
  STEP_VOUS_US_PERSON: 'Deuxième langue',
  STEP_VOUS_COMMENT: 'Commentaire Informations civiles',
  STEP_VOUS_TITLE: 'Commençons par vous...',
  STEP_CONJOINT_STEPPER_TITLE: 'Informations du conjoint',
  STEP_CONJOINT_WEDDING: 'Situation matrimoniale',
  STEP_CONJOINT_PARTNER: 'Informations du conjoint',
  STEP_CONJOINT_CONTACT: 'Coordonnées du conjoint',
  STEP_CONJOINT_JOB: 'Situation professionnelle du conjoint',
  STEP_CONJOINT_FAMOUS: 'Célébrité',
  STEP_CONJOINT_US_PERSON: 'Deuxième langue du conjoint',
  STEP_CONJOINT_COMMENT: 'Commentaire Conjoint',
  STEP_CONJOINT_TITLE: 'Racontez-nous tout sur votre moitié',
  STEP_FAMILLE_STEPPER_TITLE: 'Informations famille',
  STEP_FAMILLE_TITLE: 'Famille',
  STEP_FAMILLE_COMMENT: 'Commentaire Personnes à charge',
  STEP_BIENSIMMO_STEPPER_TITLE: 'Informations des biens immobiliers',
  STEP_BIENSIMMO_TITLE: 'Biens immobiliers',
  STEP_BIENSIMMO_COMMENT: 'Commentaire',
  STEP_AVOIRSFI_STEPPER_TITLE: 'Avoirs financiers',
  STEP_AVOIRSFI_TITLE: 'Informations des avoirs financiers',
  STEP_AVOIRSFI_COMMENT: 'Commentaire',
  STEP_BIENSDIV_STEPPER_TITLE: 'Informations des biens divers',
  STEP_BIENSDIV_TITLE: 'Biens divers',
  STEP_BIENSDIV_COMMENT: 'Vous êtes passionné-e ? Nous aussi ! Parlez nous de ce qui vous tient à coeur !',
  STEP_COMPLEMENT_STEPPER_TITLE: 'Informations complémentaires',
  STEP_COMPLEMENT_TITLE: 'C\'est bientôt fini, encore un effort ! (texte manquant)',
  STEP_COMPLEMENT_SUBTITLE1: 'Crédit',
  STEP_COMPLEMENT_SUBTITLE2: 'Revenus fiscaux',
  STEP_COMPLEMENT_SUBTITLE3: 'Donations et testaments ',
  STEP_COMPLEMENT_SUBTITLE4: 'Contrats de prévoyance',
  STEP_COMPLEMENT_COMMENT: 'Commentaire',
  RESUME_TITLE_BEFORE_AMOUNT: 'Patrimoine total estimé : ',
  RESUME_SUBTITLE: 'Récapitulatif',
  RESUME_COMMENT: 'Commentaire Récapitulatif',
  END_TEXT: 'Félicitations ! Vous avez fini le Marathon et nous vous remercions !',
  STEP_RECAP_DONATION_TESTAMENT: 'Donations et testaments',
  STEP_RECAP_DOC_INDISPENSABLE: 'Documents indispensables',
  STEP_RECAP_PERSONNE_A_CHARGE: 'Personnes à charge',
  STEP_RECAP_CONJOINT: 'Conjoint',
  STEP_RECAP_ENFANT: 'Enfants',
  STEP_RECAP_IMPOT: 'Impôts',
  STEP_RECAP_PREVOYANCE: 'Prévoyance',

  //cause rempli par un tier
  CAUSE_REMPLI_PAR_AUTRE: 'Autre ( Un tiers pour aider )',
  PERSONNE_MINEURE:'Une personne mineure',
  PERSONNE_SOUS_TUTELLE: 'Une personne sous tutelle',
  PERSONNE_SOUS_CURATELLE: 'Une personne sous curatelle'
};
