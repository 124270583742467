export enum CustomerOrigin {
  EMD = 'EMD',
  CPS = 'CPS',
  ACTI = 'ACTI',
  GOSSELIN = 'GOSSELIN',
  WING_FINANCE = 'WING_FINANCE',
  ARTHEMIS = 'ARTHEMIS',
  PERFORMANCES_BY_EMD = 'PERFORMANCES_BY_EMD',
  ANTOINE_LEDUC = 'ANTOINE_LEDUC',
  CARVING = 'CARVING'

}
